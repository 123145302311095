<template>
  <div class="church-list template-1">
    <header class="page-header container mx-lg header">
      <h1>{{ translations.tcPresenterHistory }}</h1>
      <QuickJump
        :presenterKeyProp="this.userSelectedIndividualKey"
        @presenter_changed="presenter_changed()"
        :i18n="translations.components"
      ></QuickJump>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcToolTip"
        :i18n="translations.components"
      ></camp-selector>
      <data-table
        v-if="translations.components"
        :fields="dataTableFields"
        :items="items"
        :hiddenItems="hiddenItems"
        :includeAddButton="false"
        :searchTerm="`presentersTerm`"
        :csvUrl="churchPresentersByCampAsCSVUrl"
        :i18n="translations.components"
      ></data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import QuickJump from '@/components/quick-jump/QuickJumpPresenter.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'presenter-history',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      dataTableFields: [],
      hiddenItems: {
        display: false,
      },
    }
  },
  methods: {
    ...mapActions({
      loadChurchPresenterHistoryReportUrls: 'churchMinistry/loadChurchPresenterHistoryReportUrls',
      loadPresenters: 'churchMinistry/loadQualifiedPresenters',
      loadPresenterHistory: 'churchMinistry/loadPresenterHistory',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async presenter_changed() {
      await this.page_load()
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.loadPresenters(),
          this.loadPresenterHistory(this.userSelectedIndividualKey),
          this.loadChurchPresenterHistoryReportUrls(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        { key: 'presentation_date', label: `${this.translations.tcPresentationDate}`, sortable: true },
        {
          key: 'church_name',
          label: `${this.translations.tcChurchName}`,
          sortable: true,
        },
        {
          key: 'city_state',
          label: `${this.translations.tcCityState}`,
          sortable: true,
        },
        {
          key: 'attendance',
          label: `${this.translations.tcAttendance}`,
          sortable: true,
        },
        {
          key: 'offering_amount',
          label: `${this.translations.tcOfferingAmount}`,
          sortable: true,
        },
      ]
    },
  },
  computed: {
    ...mapGetters({
      churchPresentersByCampAsCSVUrl: 'churchMinistry/datalist1CSVUrl',
      churchPresentersByCampAsPDFUrl: 'churchMinistry/datalist1PDFUrl',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      presenters: 'churchMinistry/presenterList',
      presenterHistory: 'churchMinistry/presenterHistory',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    allowPage() {
      return true
      // ASK JOHN L ABOUT THIS
      // return this.isInMenu(this.$route.path)
    },
    items() {
      return this.presenterHistory.map((el) => {
        return {
          presentation_date: el.presentation_date && el.presentation_date.substring(0, 10),
          church_name: el.church_name,
          mtg_key: el.mtg_key,
          offering_amount: el.offering_amount,
          chu_org_key: el.org_key,
          attendance: el.attendance,
          city_state: el.city + ', ' + el.state,
        }
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('data-table', 'camp-select', 'quick-jump-presenter', 'security-banner'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    QuickJump: QuickJump,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.church-list {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}
</style>
